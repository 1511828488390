var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "px-lg-5", attrs: { fluid: "" } },
    [
      _c("app-header", {
        attrs: { title: _vm.title, breadcrumbs: _vm.breadcrumbs }
      }),
      _c("v-toolbar", {
        attrs: { flat: "" },
        scopedSlots: _vm._u([
          {
            key: "extension",
            fn: function() {
              return [
                _c(
                  "v-tabs",
                  {
                    attrs: { "align-with-title": "", "center-active": "" },
                    model: {
                      value: _vm.tab,
                      callback: function($$v) {
                        _vm.tab = $$v
                      },
                      expression: "tab"
                    }
                  },
                  [
                    _c("v-tabs-slider"),
                    _c("v-tab", [
                      _vm._v(_vm._s(_vm.$t("layout.mainMenu.pending")))
                    ]),
                    _c("v-tab", [
                      _vm._v(_vm._s(_vm.$t("layout.mainMenu.history")))
                    ])
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c(
        "v-tabs-items",
        {
          model: {
            value: _vm.tab,
            callback: function($$v) {
              _vm.tab = $$v
            },
            expression: "tab"
          }
        },
        [
          _c(
            "v-tab-item",
            [
              _c(
                "v-card",
                { attrs: { flat: "" } },
                [
                  _c(
                    "v-card-text",
                    [
                      _c("leaves-table", {
                        attrs: {
                          leaves: _vm.managerPendingLeaves,
                          tableName: _vm.leaveTables.MANAGER_PENDING_REQUESTS,
                          loading: _vm.loading
                        },
                        on: {
                          manageLeave: _vm.manageLeave,
                          updateOptions: _vm.updateOptions,
                          deleteLeaveRequest: _vm.deleteLeaveRequest
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-tab-item",
            [
              _c(
                "v-card",
                { attrs: { flat: "" } },
                [
                  _c(
                    "v-card-text",
                    [
                      _c("leaves-table", {
                        attrs: {
                          leaves: _vm.managerHistoryLeaves,
                          tableName: _vm.leaveTables.MANAGER_HISTORY_REQUESTS,
                          loading: _vm.loading
                        },
                        on: {
                          manageLeave: _vm.manageLeave,
                          updateOptions: _vm.updateOptions
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }